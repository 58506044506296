

.buy-button {
    width: 100%;
    height: 50px;
    background-color: #1b5e20;
    color:whitesmoke;
    font-size: 16pt;
}

.product-figure {
    position: absolute;
}


.for-sale-badge{
    position: absolute;
    
    bottom:0px;
    width: 100%;
    // left: 50%;
    // margin-left: -25%;
    height: 40px;
    background:rgba($color: #b71c1c, $alpha: 0.7);
    
    text-align: center;
    // border-radius: 30px 30px 30px 30px;
    color:white;
    // padding:5px 5px;
    // margin-left:5px;
    // margin-right:10px;
    font-size:20pt;
    z-index: 50;
    pointer-events: all;

    &:hover {
        color: #212121;
    }
}




.product-badge {
    pointer-events: none;
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
    margin-top: 0px;
    z-index: 0;
}