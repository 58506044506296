@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Recursive:wght@700&display=swap');

.logo-img {
  margin: 0;
  margin-top: 8px;
  height: 50px;
}

$background-purple: #EEEEEE;
$subtle-white: #f9f9f9;
$subtle-grey: #f2f2f2;
$masked-grey: #333;
$blue: #F03861;


/**************/


/***********************/

.mobile-bar {
  position: fixed;
  width:100%;
  height: 60px;
  background-color: white;
}


.mobile-header {
  width:100%;
  margin-top: 60px;
  // padding-top: 5px;
  // margin-bottom: 70px;
  // height: 60px;
  background-color: white;
}

.mobile-title {
  text-transform: uppercase;
  font-size: 20pt!important;
  width: 100%;
  text-align: center;
  font-family: "Overpass", sans-serif;
  font-weight: 500;
  line-height: 2.0em;
}

/***********************/
/* FOR THE IMAGE SWIPER */

.swipe-image {
  object-fit: fill;
  height: auto;
  margin-top: 0px;
  width: 100% !important;
}

.swiper-container-installs {
  margin-top: 0px;
  width: 100%;
  height: 300px;
}

// makes background non-transparent for smaller images
.swiper-slide {
  background-color: $subtle-white;
}
.swipe-image-installs {
  object-fit: cover;
  // height: auto;
  // width: auto;
  margin-top: 0px;
  width: 100%;
  height: 300px;
}

// .swiper-container {
//   margin-top: 0px;
//   width: 100%;
//   height: 300px;
// }

.swiper-container-hero {
  margin-top: 0px;
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  outline : none;
}

// Move the pagination bullets up some so that they can be seen on mobile view
.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
 bottom:40px !important;
}

.swiper-pagination-bullet {
  // background-color: #CCC!important;
  background-color: rgba(0, 0, 0, 0)!important;
  opacity: 0.9!important;
  border:1px solid #eeeeee;    
  // border-radius:50%;
  // -moz-border-radius:50%;
  // -webkit-border-radius:50%;
  // width:100px;
}

.swiper-pagination-bullet-active{
  // background: var(--swiper-pagination-color,var(--swiper-theme-color))!important;
  background-color: #eeeeee!important;
}


.site-title a {
  position: absolute;
  left: 25%;
  top: 0;
  color: #333;
  text-decoration: none !important;
}

/* header */
.nav-spacer {
    min-height: 0px;
}



  
  
  /* 48em = 768px */
  
  @media (min-width: 1024px) {
    
    .swiper-container-hero {
      margin-top: 5px;
      width: 80%;
      height: 700px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: flex !important;
    }

    // unset the above override for desktop
    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom:10px !important;
    }
    

    

  }
  