
@media only screen and (min-width : 1024px) {

  .swiper-container-hero {
    margin-top: 0px;
    width: 84%;
    max-width: 1800px;
  }

  .lg-header {
    width:100%;
    margin-top: 80px;
    margin-bottom: 60px;
    background-color: $subtle-white;
  }

  .lg-title {
    text-transform: uppercase;
    font-size: 32pt!important;
    width: 100%;
    text-align: center;
    font-family: "Overpass", sans-serif;
    font-weight: 500;
    line-height: 2.0em;
  }

  .swiper-container-installs {
    margin-top: 0px;
    width: 100%;
    height: 500px;
  }

  .swiper-slide {
    background-color: $subtle-white;
  }
  .swipe-image-installs {
    object-fit: contain;
    height: auto;
    margin-top: 0px;
    width: 100%;
    height: 500px;
  }


  .header  .cart-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    padding-right: 10px;
    padding-top: 4px;
  }

  .swipe-image {
    object-fit: fill;
    height: auto;
    margin-top: 0px;
    width: 100%;
    max-height: 900px;
    max-width: 1800px;
  }

  .wrapper {
      max-width: 1010px;
      width: 100%;
      margin: 0 auto;
  }


  .header .menu {
    padding-top: 0px;
    margin-top: 0px;
    height: 60px;
    width: 100%;
  }

}


@media only screen and (min-width : 1201px) {
    .wrapper {
        max-width: 1021px;
        // width: 50%;
        margin: 0 auto;
    }

    .header li a {
      padding: 20px 15px;
      font-size: 16pt;
    }
}