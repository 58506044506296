.header .cart-btn {
position: absolute;
right: 0px;
top: 0px;
display: none;
}

.header  .cart-icon {
position: absolute;
right: 0px;
top: 0px;
width: 32px;
height: 32px;
padding-right: 10px;
padding-top: 4px;
}


.header .menu-btn {
    display: none;
}


@media (max-width: 1024px) {

    .header {
    background-color:rgba(0, 0, 0, 0);
    // background-color: #3452FF;
    color: #eeeeee;
    // opacity: 0.3;
    // box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
    }

    .header ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    overflow: hidden;
    // background-color: #fff;
    // display: flex;
    flex-direction: column;
    // justify-content: center;
    z-index: 10;
    // justify-content: center;
    // display: flex;
    }

    // .header ul > li:nth-child(1) {
    // padding-top: 60px;
    // }

    .header li {
        order: 1;
        text-align: center;
        width: 50%;
        margin: auto;
    }


    .header li a {
    display: block;
    padding: 10px 10px;
    // border-right: 1px solid #f4f4f4;
    text-decoration: none;
    text-transform: uppercase;
    color: #FFF;
    font-size: 16pt;
    font-weight: 300;
    // line-height: 1em;

    }


    .header li a:hover,
    .header .menu-btn:hover {
    // background-color: #f4f4f4;
    // font-weight: 700;
    }

    .header .logo {
    display: block;
    text-align: center;
    // float: left;
    font-size: 2em;
    // padding: 10px 20px;
    text-decoration: none;
    }



    .header .menu {
    // clear: both;
    max-height: 0;
    height: 640px;
    width:75%;
    left: -75%;
    // transition: max-height .2s ease-out;
    transition: all .2s ease-out;

    margin-top: 60px;
    }



    .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: left;
    padding: 28px 20px;
    position: relative;
    user-select: none;
    }

    .header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
    }

    .header .menu-icon .navicon:before {
    top: 5px;
    }

    .header .menu-icon .navicon:after {
    top: -5px;
    }

    

    .header .menu-btn:checked ~ .menu {
    max-height: 2000px;
    height: 700px;
    width: 100%;
    left:0;
    background-color: #3452FF;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
    }

    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
    }


    .header ul {
        li {
            width:75%;
            &:nth-child(1) {
                padding-top: 60px;
            }
            ul {
                li {
                    width:100%;
                    text-align: left;
                    padding-left: 30%;
                    &:nth-child(1) {
                        padding-top: 0px;
                    }
                    a {
                        padding: 4px 10px;
                        &:before {
                            content: "";
                        }
                        font-size: 14pt;
                    }
                }
            }
        }
    } // .header ul


    .toggle {
        display: block;
        // background-color: #254441;
        // padding:14px 20px;	
        color:#FFF;
        // font-size:17px;
        // text-decoration:none;
        border:none;
        &:after {
            content: " + "
        }
    }

    //  .toggle {
    //     &:after {
    //         content: " - "
    //     }
    // }

    /* Display Dropdown when clicked on Parent Lable */
    [id^=drop]:checked + ul {
        display: block;
        max-height: 400px;
    }

    .header ul ul {
        // display: none;
        transition: all .3s ease-in-out;
        display: block;
        max-height: 0px;
        position: relative; 
        /* has to be the same number as the "line-height" of "nav a" */
        top: 0px; 
    }

    .menu-link {
        display: block;
        padding: 10px 10px;
        // border-right: 1px solid #f4f4f4;
        text-decoration: none;
        text-transform: uppercase;
        color: #FFF;
        font-size: 16pt;
        font-weight: 300;
        overflow-x: visible;
    }

}



[id^=drop]{
    display: none;
}