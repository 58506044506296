@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;700&display=swap');

$background-purple: #EEEEEE;
$subtle-white: #f9f9f9;
$subtle-grey: #f2f2f2;
$masked-grey: #333;
$blue: #F03861;

@import 'photoswipe.scss';
@import 'header.scss';
@import 'forms.scss';
@import 'menu-mobile.scss';
@import 'menu-desktop.scss';


.shopify-buy-frame--toggle.is-sticky {
    top:25%!important;
}

.bhl {
    font-weight: bold;
    font-size: 150%;
}

@media screen and (max-width: 600px) {
    .mobile-hidden {
        display: none!important;
    }

    div > p:nth-child(1){
        // margin-top: 0px;
        margin-block-start: 0px;
    }
}

@media screen and (min-width: 600px) {
    .mobile-only {
        display: none!important;
    }

}


// simple prevention of image download
img {
    pointer-events: none;
}

.fancy-link {
    border-bottom: #EA215A 0.125em solid;
}



body {
    font-family: "Overpass", sans-serif;
    font-weight: 400;
    color: #333;
    line-height: 1.6;
    font-size: 16px;
    padding-right: 0px;
    padding-left: 0px;

    overflow-x: hidden; //horizontal
    overflow-y: scroll; //vertical
}

a, a:hover {
    color: #a00;
    text-decoration: none;
    word-break: break-word;
}

html,button,input,select,textarea {
    color: #333;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1rem 0;
    padding: 0;
}

img {
    margin: 10px auto 10px auto;
    max-width: 100%;
    display: block;
}

a img {
    border:none;
}

figure {
    margin: 0;
    text-align: center;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

blockquote {
    margin-left: 1rem;
    font-style: italic;
    font-size: 1.4rem;
    font-family: Georgia,bitstream charter,serif;
    border-left: 3px solid;
    border-color: #a00;
    padding-left: 20px;
}

blockquote cite {
    font-size: 70%;
    opacity: .8;
}

blockquote em {
    font-weight: 600;
}

a,a:hover {
    color: #a00;
    text-decoration: none
}

a:hover {
    text-decoration: underline
}

h1,h2,h3,h4,h5,h6 {
    font-family: "Overpass",sans-serif;
    line-height: 1.2;
    color: #333;
    font-weight: 300;
}

h1 {
    font-size: 2.75rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.2rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: .9rem;
}

.align-center {
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.container {
    max-width: 100%; 
}

ul {
    padding-left: 15px;
}

ul.flat {
    margin: 0;
    padding: 0;
}

ul.flat li {
    display: inline-block;
    list-style: none;
    margin-left: 0;
}

.prevent-collapse {
    min-height: .1rem
}

.page-title {
    margin: 0;
}

.smaller {
    font-size: 70%;
}

ul {
    list-style: disc inside;
}

.post ul li {
    margin-bottom: 10px;
}

.post ul li p {
    display: inline;
}

.highlight pre {
    margin-bottom: 0;
    margin-top: 0;
    padding: 20px;
    background-color: transparent !important;
}

.highlight {
    background: 0 0;
    background-color: #FAFAFA;
}

.wrapper {
    max-width: 760px;
    width: 95%;
    margin: 0 auto;
    padding-left: 4px;
    padding-right: 4px;
}

.container {
    margin-top: 0px;
}

.header h1,
.header h2 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    line-height: 1.3em;
}

.header h2 {
    font-size: 1.125rem;
}

.header .nav-border {
    padding-top: 24px;
    border-bottom: 1px solid #f4f4f4;
}

.nav.social {
    /* float: right; */
}

.header nav {
    margin-bottom: 10px;
    margin-top: -25px;
    /* border-bottom: 1px solid #f4f4f4; */
}

.header nav ul,
.header nav li {
    margin: 0;
}

.header nav ul.flat {
    padding: 0;
}

.header nav ul.flat li {
    display: inline-block;
    list-style: none;
    margin-left: 0;
    margin-right: 10px;
    margin-top: 10px;
    text-transform: capitalize;
    float: right;
}

.header .site-description {
    display: flex;
    justify-content: space-between;
}

.header .site-description nav {
    margin: 0;
    padding: 0;
    border: none;
    min-width: 50px;
    margin-left: 15px;
}

.header .site-description nav ul svg {
    max-height: 25px;
}

.section .section-header {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #999;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.recent-posts .posts .post {
    margin-bottom: 30px;
}

.recent-posts .posts .post .meta,
.post .post-header .meta,
.list .posts .post .meta {
    font-size: 0.725rem;
    color: #999;
    margin-bottom: 4px;
}

.post .post-header {
    margin-bottom: 30px;
}

.post .post-header .title {
    margin: 0;
}

.post-tags a {
	margin-right: 5px;
}

.post .post-header .meta {
    padding-left: 5px;
    margin-top: 10px;
}

.post .draft-label {
    color: #a00;
    text-decoration: none;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 6px;
    background-color: #f9f2f4;
}

.post-tags .tags li {
    margin-bottom: 0;
    margin-top: 20px;
}

.post-tags .tags a {
    display: inline-block;
    border: 1px solid #a00;
    border-radius: 4px;
    padding: 0px 6px;
    color: #a00;
    line-height: 20px;
    font-size: 12px;
    text-decoration: none;
    margin: 0 1px;
}

.list .posts .post .meta {
    margin-bottom: 0;
    margin-left: 5px;
}

.footer {
    text-align: right;
    font-size: 0.75em;
    color: #999;
    border-top: 1px solid #f4f4f4;
    padding: 20px 0;
    margin-top: 40px;
}

.page-title {
    margin-bottom: 0;
}

.tag-cloud {
    margin-top: 20px;
}

.tag-cloud a {
    margin-right: 15px;
}

.pagination {
    margin: 0;
    padding: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.pagination li {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.pagination .page-prev {
    margin-right: 20px;
    padding-right: 20px;
}

.pagination .page-item.page-prev {
    text-align: left;
}

.pagination .page-item.page-next {
    text-align: right;
}

table th {
    background-color: #f4f4f4;
}

table th, table td {
    padding: 10px 20px;
    border: 1px solid #dddddd;
}

@media (max-width: 767px) {
    // prevent horizontal scrolling
    html, body {
      overflow-x: hidden;
    }
    body {
      position: relative
    }
    // prevent horizontal scrolling
    body {
        // padding: 4px;
    }

    h1 {
        font-size: 1.8rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size: 1rem;
    }

    .container {
        margin-top: 0px;
    }
}


.nav-flex {
    display: flex;
}
.nav-flex-title {
    flex-grow: 1;
}
.nav-flex-social {
    text-align: center;
    margin-top: 20px;
    margin: 0 auto;
}

.icon,
.icon.is-large,
.icon.is-medium,
.icon.is-small {
    display: inline-block;
    vertical-align: top;
    text-align: center;
}

.icon {
    height: 24px;
    width: 24px;
    font-size: inherit;
    line-height: inherit;
}

.icon.is-small {
    font-size: 14px;
    height: 16px;
    line-height: 16px;
    width: 16px;
}

.icon.is-medium {
    font-size: 28px;
    height: 32px;
    line-height: 32px;
    width: 32px;
}

.icon.is-large {
    font-size: 42px;
    height: 48px;
    line-height: 48px;
    width: 48px;
}

.nav-item {
    text-align: center;
}


.level-item {
    margin-left: 5px;
    margin-right: 5px;
    /* height: 25px;
    width: 100%;
    display: inline-block;
    min-height: 25px; */
}

.level-item {
            min-height: 50px;
            // stroke: currentColor;
            stroke: #333;
            fill: none;
            // float: right;
            /* position: relative; */
            /* top: 0.1111111111111111em; */
}

.icon-me svg:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
//   animation: shake 0.5s;

  /* When the animation is finished, start again */
//   animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


/*************************************/
.wireframe {outline: 2px solid gray;
   position: relative; z-index: 1;}
.wireframe::before {
   position: absolute; z-index: 8675309;
   top: 0; bottom: 0; right: 0; left: 0;
   background:
      linear-gradient(to top right,
         transparent calc(50% - 1px), gray calc(50% - 1px),
         gray calc(50% + 1px), transparent calc(50% + 1px)),
      linear-gradient(to bottom right,
         transparent calc(50% - 1px), gray calc(50% - 1px),
         gray calc(50% + 1px), transparent calc(50% + 1px)),
      #FFF9;
      content: "";
}
html {
   --fill: #FFFFFF;
   --wire: gray;
}
[data-wf] {outline: 2px solid var(--wire);
   position: relative; z-index: 1;}
[data-wf]::before {
   position: absolute; z-index: 8675309;
   top: 0; bottom: 0; right: 0; left: 0;
   background:
      linear-gradient(to top right,
         transparent calc(50% - 1px), var(--wire) calc(50% - 1px),
         var(--wire) calc(50% + 1px), transparent calc(50% + 1px)),
      linear-gradient(to bottom right,
         transparent calc(50% - 1px), var(--wire) calc(50% - 1px),
         var(--wire) calc(50% + 1px), transparent calc(50% + 1px)),
      var(--fill);
      content: attr(data-wf);
      font: bold 2em Jubilat, Georgia, serif;
      color: var(--wire);
      text-shadow:
         0 0 0.25em var(--fill), 0 0 0.25em var(--fill),
         0 0 0.25em var(--fill), 0 0 0.25em var(--fill),
         0 0 0.25em var(--fill);
      display: flex; justify-content: center; align-items: center;
}


.btn {
display: inline-block;
padding: 0.5em 1.5em;
border-radius: 3px;
cursor: pointer;
box-shadow: inset 0 -0.1em 0 0 rgba(0, 0, 0, 0.2), inset 0 1.15em 0 0 rgba(255, 255, 255, 0.1); }
.btn:active {
  transform: translateY(2px);
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.2); }

  @media (max-width: 767px) {
      .demo-controls {
        display: none;
      }
  }

.footer-container {
    margin-top: 50px;
}

@import 'screen-lg.scss';



figcaption a {
    display: none;
    white-space: nowrap;
    position: relative!important;

}

/*
.pswp__top-bar {
    background-color: #222!important;
    opacity: 1.0;
    // color: #000;
}

.pswp__button--arrow--right::before .pswp__button--arrow--left::before { 
    background-color: #222!important;
}

.Pswp_bg image-zoom-background {
    background-color: #fff !important;
}*/

/*
.pswp__bg {
    background-color: #fff !important;
}



.pswp__top-bar {
    background-color: #222!important;
    opacity: 1.0;
    // color: #000;
}

.pswp__caption__center {
    color: #000!important;
}

.pswp__button--arrow-left {
    background-color: #222!important;
}

.Pswp_bg image-zoom-background {
    background-color: #fff !important;
}
*/

// .pswp__img {
//     max-width: none;
//     object-fit: contain;
// }


// Contact page rough spacing 
@media screen and (max-width: 1024px) {
    .contact-images {
        height: 250px;
        overflow: hidden;
    }
    .live-map {
        margin-top: 40px;
        min-height: 400px;
    }

    .storefront {
        max-height: 400px;
    }
}
@media screen and (min-width: 1024px) {
    .contact-images {
        height: 450px;
        overflow: hidden;
    }

    .live-map {
        margin-top: 40px;
        min-height: 400px;
    }

    .storefront {
        max-height: 450px;
    }
}

@media screen and (max-width: 1024px) {
    .notify-badge{
        position: absolute;
        right:10px;
        bottom:10px;
        background:red;
        text-align: center;
        border-radius: 30px 30px 30px 30px;
        color:white;
        padding:4px 10px;
        font-size:12px;
        z-index: 2;
    }
}

@media screen and (min-width: 1024px) {
    .notify-badge{
        position: absolute;
        right:10px;
        bottom:10px;
        background:red;
        text-align: center;
        border-radius: 30px 30px 30px 30px;
        color:white;
        padding:4px 10px;
        font-size:20px;
        z-index: 2;
    }
}


.sale-hero-button2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    text-transform: capitalize;
    text-align: center;
    // background-color:#b3d4fc;
    width:50%; 
    height:60px;
}

.sale-hero-button {
    display: flex;
    width: 90%;
    height: 70px;
    margin: auto;
    border-radius: 10px;
    // border: 3px dashed #1c87c9;
    background-color: #b3d4fc;
    align-items:center;
    justify-content:center;
}

.sale-hero-link:hover {
    background-color: #b3ffb3;
}

.sale-hero-link {
    font-size: 16pt;
    font-weight: 600;
    
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
    border-radius: 10px;
    background-color: #b3d4fc;
    align-items:center;
    justify-content:center;
    
}

@media screen and (min-width: 1024px) {

    .sale-hero-button {
        width: 40%;
        height: 70px;
    }

}

.enclose {
    position:fixed;
    bottom:0px;
    left: 0px;
    width:100%;
    z-index: 99;
}


@import 'products.scss';