//variables

$open-sans: 'Overpass', sans-serif;


@media (min-width: 1024px) {
  .white-space {
      min-height: 40px;
  }

    body {
        background-color: $subtle-white;
    }
    .header {
      position: relative;
      background-color: $subtle-white;
    }
    .swiper-container-hero {
      margin-top: 5px;
      width: 80%;
      height: 700px;
    }

    .link-1 {
      font-size: 18pt!important;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px!important;
      padding-bottom: 0px!important;
      transition: 0.3s ease;
      // background: #3fa46a;
      color: $subtle-white;
      // font-size: 20px;
      // text-decoration: none;
      // border-top: 4px solid #3fa46a;
      border-bottom: 4px solid $subtle-white;
      padding: 20px 0;
      // margin: 0 20px;
    }
    .link-1:hover {
      // border-top: 4px solid #ff0000;
      border-bottom: 4px solid $masked-grey;
      // padding: 2px 0; 
    }

    .menu-link {
      padding: 0;
        margin: 0;
        padding: 20px 10px;
        text-decoration: none;

        color: #424242;
        text-transform: unset;
        font-size: 20pt!important;
    }

.header ul {
  font-size: 0;
  list-style-type: none;

  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: left;

  padding-bottom: 15px;

  // initial li
  li {
    font-family: $open-sans;
    font-size: 1rem;
    font-weight: 500;
    color: $masked-grey;

    display: inline-block;
    padding: 5px;
    position: relative;

    width: unset;
    text-align: left;
    margin: unset;

    a {
        padding: 0;
        margin: 0;
        padding: 20px 10px;
        text-decoration: none;

        color: #424242;
        font-size: 14pt;
        text-transform: unset;
        

        &.menu-link {
            font-size: 20pt!important;
            color: #424242;
        }
    }

     // secondary ul
      ul {
        display: none;
      }

    // initial li:hover
    &:hover {
      cursor: pointer;
    //   background-color: $subtle-grey;

      // secondary ul visibility change
      ul {
        display: block;
        margin-top: 2px;

        width: 300px;
        left: 0;

        position: absolute;

        // secondary li
        li {
            z-index: 10;
            display: block;
            background-color: $subtle-white;
            padding: 4px 10px;
          span {
            float: right;
            color: $subtle-white;
            background-color: $blue;
            padding: 2px 5px;
            text-align: center;
            font-size: .8rem;
            border-radius: 3px;
          }

          &:hover {
            // background-color: darken($subtle-white, 10%);
            span {
              background-color: darken($blue, 5%);
            }
          }
        }
      }
    } 
  }
}
}
