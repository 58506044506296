// PHOTO SWIPE prevent caption overlap image
.pswp__img {
    padding-bottom: 45px;
}

.pswp__caption {
    background-color: #fff !important;
    // color: #000;
    margin-bottom: 0px!important;
}

.pswp__caption__center {
    color: #000!important;
    p {
        display: inline;
    }
}

// .pswp__container { transition: transform 0.35s ease-in-out; }

/*
	div element that matches size of large image
	large image loads on top of it
*/
.pswp__img--placeholder--blank {
    background: #fff!important;
    display: none;
 }

// This appears to fix the aspect ratio issue in the gallery
// probably result from conflicts from css on img somewhere else
.pswp * {
    -webkit-box-sizing: content-box!important;
            box-sizing: content-box!important; }